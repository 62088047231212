

























































































import {
  ref,
  defineComponent,
  watch,
  watchEffect,
  PropType,
  computed,
  onMounted
} from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';
import MCalendar from '@/components/molecules/MCalendar.vue';
import MInternsBadge from '@/components/molecules/MInternsBadge.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleDefault',
  components: {
    MCalendar,
    MInternsBadge
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    adkIndex: {
      type: Number,
      default: 0
    },
    adkType: {
      type: String,
      default: ''
    },
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    }
  },
  setup(props: any, ctx: any) {
    /** actions */
    const { user }: any = useUserState(['user']);
    const { getAuthUrl } = useUserActions(['getAuthUrl']);
    const { updateCalendar } = useUserActions(['updateCalendar']);
    const { fetchStudentById } = useUserActions(['fetchStudentById']);
    // const { collection } = useDbGetters(['collection']);
    const { disconnectUserFromCalendar } = useUserActions(['disconnectUserFromCalendar']);
    const { refreshCalendarTokenData } = useUserActions(['refreshCalendarTokenData']);
    const { getUserByKeyAndValue } = useUserActions(['getUserByKeyAndValue']);
    const connectWithGoogleProgress = ref(false);
    const connectWithOutlookProgress = ref(false);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === props?.adkType;
      }
      return false;
    });
    function goToCalendar() {
      if (programDoc?.value?.data?.outlookDetails) {
        window.open('https://outlook.live.com/calendar', '_blank');
      } else {
        window.open('https://calendar.google.com/calendar', '_blank');
      }
    }
    const isScopeUnchecked = ref(false);
    watchEffect(() => {
      if (ctx.root.$route.query.isScopeUnchecked) {
        isScopeUnchecked.value = true;
      }
    });
    function filterAccessTokenAndType() {
      let accessToken;
      let google;
      if (programDoc?.value?.data?.googleDetails) {
        accessToken = programDoc?.value?.data?.googleDetails?.access_token;
        google = true;
      } else if (programDoc?.value?.data?.outlookDetails) {
        accessToken = programDoc?.value?.data?.outlookDetails?.access_token;
        google = false;
      }
      return { accessToken, google };
    }
    const isDisconnect = ref(false);
    async function disconnect() {
      isDisconnect.value = true;
      await disconnectUserFromCalendar({
        document_type: 'Student',
        document_id: props?.value?.data?._id?.toString(),
        public_id: props?.value?.data?._id?.toString()
      });
      props.fetchProgram();
      setTimeout(() => {
        isDisconnect.value = false;
      }, 2000);
    }
    const url = ref();
    /** Methods */
    async function refreshAccessToken(refreshToken, type) {
      const response = await refreshCalendarTokenData({
        refreshToken,
        type,
        document_id: props?.value?.data?._id?.toString(),
        document_type: 'Student',
        adk_type: props?.adkType
      });
      if (response === undefined) {
        await disconnectUserFromCalendar({
          document_type: 'Student',
          document_id: props?.value?.data?._id?.toString(),
          public_id: props?.value?.data?._id?.toString()
        });
        props.fetchProgram();
      }
      return response;
    }

    if (programDoc?.value?.data?.googleDetails) {
      refreshAccessToken(programDoc?.value?.data?.googleDetails?.refresh_token, 'google');
    } else if (programDoc?.value?.data?.outlookDetails) {
      refreshAccessToken(programDoc?.value?.data?.outlookDetails?.refresh_token, 'outlook');
    }

    async function updateAttendees(eventId, email, google, eventData, events) {
      const userByEmail = await getUserByKeyAndValue({
        key: 'email',
        value: decodeURIComponent(email)
      });
      // collection.value!('User').findOne({
      //   email
      // });
      let userAccessToken;
      let refreshedAccessTokenData;
      let oldRefreshToken;
      if (google) {
        userAccessToken = userByEmail.googleDetails.access_token;
        oldRefreshToken = userByEmail.googleDetails.refresh_token;
      } else {
        userAccessToken = userByEmail.outlookDetails.access_token;
        oldRefreshToken = userByEmail.outlookDetails.refresh_token;
      }
      const response = await updateCalendar({
        eventId,
        refreshToken: oldRefreshToken,
        accessToken: userAccessToken,
        google,
        eventData
      });
      if (!response.error) {
        programDoc.value.data.adks[index].events = events;
        programDoc.value.update();
      } else if (response.error && response.error.code === 'UNAUTHORIZED') {
        if (google) {
          refreshedAccessTokenData = await refreshCalendarTokenData({
            refreshToken: userByEmail.googleDetails.refresh_token,
            type: 'google',
            document_id: props?.value?.data?._id?.toString(),
            document_type: 'Student',
            adk_type: props?.adkType
          });
          await updateCalendar({
            eventId,
            refreshToken: oldRefreshToken,
            accessToken: refreshedAccessTokenData.access_token,
            google,
            eventData
          });
        } else {
          refreshedAccessTokenData = await refreshCalendarTokenData({
            refreshToken: userByEmail.outlookDetails.refresh_token,
            type: 'outlook',
            document_id: props?.value?.data?._id?.toString(),
            document_type: 'Student',
            adk_type: props?.adkType
          });
          await updateCalendar({
            eventId,
            refreshToken: oldRefreshToken,
            accessToken: refreshedAccessTokenData.access_token,
            google,
            eventData
          });
          programDoc.value.data.adks[index].events = events;
        }
      }
    }
    const programIdValue: any = computed(() => {
      if (props.userType === 'organizer') {
        return props.value.data._id.toString();
      }
      return props.value.data.program_id.toString();
    });
    async function connectWithGoogleOrOutlook(type) {
      if (type === 'google') {
        connectWithGoogleProgress.value = true;
      } else if (type === 'outlook') {
        connectWithOutlookProgress.value = true;
      }
      const response = await getAuthUrl({
        type,
        public_id: programDoc?.value?.data?._id?.toString()
      });
      localStorage.setItem('programId', programIdValue.value.toString());
      localStorage.setItem('documentId', props.value.data._id.toString());
      localStorage.setItem('guideAdkIndex', props?.adkIndex);
      localStorage.setItem('adkType', props?.adkType);
      localStorage.setItem('connectionType', type);
      localStorage.setItem('redirectUrl', window.location.href);
      url.value = response;
      if (type === 'google') {
        connectWithGoogleProgress.value = false;
      } else if (type === 'outlook') {
        connectWithOutlookProgress.value = false;
      }
    }

    /** Watcher */
    watch(
      () => url.value,
      urlData => {
        if (urlData !== '') {
          window.open(urlData, '_self');
          url.value = '';
        }
      }
    );
    const google = ref(false);
    const tokenData = ref();

    /** Created */
    onMounted(async () => {
      if (programDoc?.value?.data?.googleDetails) {
        google.value = true;
        tokenData.value = programDoc?.value?.data?.googleDetails;
      } else if (programDoc?.value?.data?.outlookDetails) {
        tokenData.value = programDoc?.value?.data?.outlookDetails;
      }
      const studentDocValue = await fetchStudentById({
        program_id: programDoc?.value?.data?.program_id?.toString(),
        user_id: user.value?._id.toString(),
        public_id: programDoc?.value?.data?._id?.toString()
      });
      if (studentDocValue && studentDocValue?.adks) {
        // const isInternAlreadyRemoved = studentDocValue.adks.find(
        //   adk => adk.name === 'meet' && adk.removedFromCalendar
        // );
        const meetAdk = programDoc?.value?.data?.adks?.find(adk =>
          adk.fields?.find(field => field.name === 'm-meet-default')
        );
        if (meetAdk && !meetAdk?.removedFromCalendar) {
          if (programDoc?.value?.data?.googleDetails || programDoc?.value?.data?.outlookDetails) {
            const weekltMeetingEvents = props.value.data.adks[index]?.events;
            if (
              weekltMeetingEvents &&
              weekltMeetingEvents?.weekly_meeting &&
              weekltMeetingEvents?.weekly_meeting_participants &&
              weekltMeetingEvents?.weekly_meeting_participants.includes('intern')
            ) {
              const organizerUser = await getUserByKeyAndValue({
                key: 'email',
                value: decodeURIComponent(weekltMeetingEvents?.created_by)
              });
              // await collection.value!('User').findOne({
              //   email: weekltMeetingEvents?.created_by
              // });
              let attendeesEmail;
              if (
                programDoc?.value?.data?.googleDetails &&
                programDoc?.value?.data?.googleDetails?.email
              ) {
                attendeesEmail = programDoc?.value?.data?.googleDetails.email;
              } else if (
                programDoc?.value?.data?.outlookDetails &&
                programDoc?.value?.data?.outlookDetails?.email
              ) {
                attendeesEmail = programDoc?.value?.data?.outlookDetails?.email;
              } else {
                attendeesEmail = programDoc?.value?.data?.email;
              }
              if (programDoc?.value?.data?.googleDetails) {
                google.value = true;
                const weeklyMeetingAttendees = weekltMeetingEvents.weekly_meeting?.attendees.map(
                  attendee => attendee?.email
                );
                if (!weeklyMeetingAttendees.includes(attendeesEmail)) {
                  const newAttendees: any = weekltMeetingEvents.weekly_meeting.attendees.filter(
                    value => value
                  );
                  newAttendees.push({
                    email: attendeesEmail
                  });
                  weekltMeetingEvents.weekly_meeting.attendees = newAttendees;
                  delete weekltMeetingEvents.weekly_meeting.sequence;
                  updateAttendees(
                    weekltMeetingEvents.weekly_meeting.id,
                    organizerUser.email,
                    google?.value,
                    weekltMeetingEvents.weekly_meeting,
                    weekltMeetingEvents
                  );
                }
              } else if (programDoc?.value?.data?.outlookDetails) {
                const weeklyMeetingAttendees = weekltMeetingEvents.weekly_meeting?.attendees.map(
                  attendee => attendee?.emailAddress?.address
                );
                if (!weeklyMeetingAttendees.includes(attendeesEmail)) {
                  const newAttendees: any = weekltMeetingEvents.weekly_meeting.attendees.filter(
                    value => value
                  );

                  newAttendees.push({
                    emailAddress: {
                      address: attendeesEmail,
                      name: user.value?.firstName + user.value?.lastName || ''
                    },
                    type: 'required'
                  });
                  weekltMeetingEvents.weekly_meeting.attendees = newAttendees;
                  updateAttendees(
                    weekltMeetingEvents.weekly_meeting.id,
                    organizerUser.email,
                    google?.value,
                    weekltMeetingEvents.weekly_meeting,
                    weekltMeetingEvents
                  );
                }
              }
            }
          }
        }
      }
      /*       if (weekltMeetingEvents && weekltMeetingEvents.coworking_participants.includes('intern')) {
          if (weekltMeetingEvents?.coworking) {
            for (let i = 0; i < weekltMeetingEvents?.coworking.length; i++) {
              const event = weekltMeetingEvents?.coworking[i];
              const coworkingAttendees = event.attendees.map(attendee => attendee?.email);
              if (!coworkingAttendees.includes(user.value.email)) {
                const { accessToken, google } = filterAccessTokenAndType();
                const newAttendees = event.attendees.filter(value => value);
                newAttendees.push({
                  email: user.value.email
                });
                weekltMeetingEvents.coworking[i].attendees = newAttendees;
                delete weekltMeetingEvents.coworking.sequence;
                updateAttendees(
                  event.id,
                  accessToken,
                  google,
                  weekltMeetingEvents.coworking[i],
                  weekltMeetingEvents
                );
              }
            }
          }
        } */
      /*       if (weekltMeetingEvents && weekltMeetingEvents.coworking_participants.includes('intern')) {
          if (weekltMeetingEvents?.coworking) {
            for (let i = 0; i < weekltMeetingEvents?.coworking.length; i++) {
              const event = weekltMeetingEvents?.coworking[i];
              const coworkingAttendees = event.attendees.map(
                attendee => attendee?.emailAddress?.address
              );
              if (!coworkingAttendees.includes(user.value.email)) {
                const { accessToken, google } = filterAccessTokenAndType();
                const newAttendees: any = event.attendees.filter(value => value);
                newAttendees.push({
                  emailAddress: {
                    address: user.value.email,
                    name: user.value?.firstName + user.value?.lastName || ''
                  },
                  type: 'required'
                });
                weekltMeetingEvents.coworking[i].attendees = newAttendees;
                updateAttendees(
                  event.id,
                  accessToken,
                  google,
                  weekltMeetingEvents.coworking[i],
                  weekltMeetingEvents
                );
              }
            }
          }
        } */
    });

    const showInstructions = ref(true);
    return {
      showInstructions,
      user,
      programDoc,
      index,
      connectWithGoogleProgress,
      connectWithOutlookProgress,
      goToCalendar,
      connectWithGoogleOrOutlook,
      isScopeUnchecked,
      disconnect,
      isDisconnect,
      google,
      tokenData
    };
  }
});
